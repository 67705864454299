import { api } from "./config"

export const clgAPI = {
  login: async function (email, password) {

    const response = await api.request({
      url: `/auth/login`,
      method: "POST",
      params: {
        email: email,
        password: password,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  checklogintoken: async function (token) {

    const response = await api.request({
      url: `/auth/check-token`,
      method: "POST",
      params: {
        token: token,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  resetPassword: async function (token, password) {

    const response = await api.request({
      url: `/auth/reset-password`,
      method: "POST",
      params: {
        token: token,
        password: password,
      },
    })

    // returning the product returned by the API
    return response
  },

  forgotPassword: async function (email) {

    const response = await api.request({
      url: `/auth/forgot-password`,
      method: "POST",
      params: {
        email: email,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** Get Today's Snowflake records */
  getSnowflakeData: async function (token) {

    const response = await api.request({
      url: `/audit/snowflakeData`,
      method: "GET",
      params: {
        token: token,
      },
    })

    console.log(response);

    // returning the product returned by the API
    return response
  },

  /** Get the APi Key */
  getDebtPayProDataApiKey: async function (token) {

    const response = await api.request({
      url: `/audit/getDPPApiKey`,
      method: "GET",
      params: {
        token: token,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** Get to corresponding Debt Pay Pro client Debt */
  getDebtPayProData: async function (apiKey, clientID) {

    const response = await api.request({
      url: `/audit/DPPdebtData`,
      method: "POST",
      params: {
        apiKey: apiKey,
        clientID: clientID,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** For the new CRS Credit Report for the client */
  getCRSData: async function (clientID, firstName, lastName, street1, street2, city, state, zip, ssn, dob) {

    //'Anderson', 'Laurie', '9817 Loop Blvd', 'Apt G', 'California City', 'CA', '935051352', '666455730'

    const response = await api.request({
      url: `/audit/crsData`,
      method: "POST",
      params: {
        clientID: clientID,
        firstName: firstName,
        lastName: lastName,
        street1: street1,
        street2: street2,
        city: city,
        state: state,
        zip: zip,
        ssn: ssn,
        dob: dob,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** Update the Debt Record */
  updateDebtRecord: async function (apiKey, debtID, originalDebtAmount, currentDebtAmount, creditor, clientID, custom, debt) {
      
    const response = await api.request({
      url: `/audit/updateDebtRecord`,
      method: "POST",
      params: {
        apiKey: apiKey, 
        debtID: debtID,
        originalDebtAmount: originalDebtAmount, 
        currentDebtAmount: currentDebtAmount,
        creditor: creditor,
        clientID: clientID,
        customDebt: custom,
        debtStatus: debt
      },
    })

    const requestOptions = {
      url: "/audit/updateDebtRecord",
      method: "POST",
      params: {
        apiKey: apiKey,
        debtID: debtID,
        originalDebtAmount: originalDebtAmount,
        currentDebtAmount: currentDebtAmount,
        creditor: creditor,
        clientID: clientID,
        customDebt: custom,
        debtStatus: debt
      }
    };

    //console.log("Request URL:", requestOptions.url);
    //console.log("Request Body:", JSON.stringify(requestOptions.params));

    // returning the product returned by the API
    return response
  },

  /** Update the Task Record */
  updateTaskRecord: async function (apiKey, clientID, taskName, definedTask, taskNote) {

    const response = await api.request({
      url: `/audit/updateTaskRecord`,
      method: "POST",
      params: {
        apiKey: apiKey, 
        clientID: clientID,
        taskName: taskName,
        definedTask: definedTask,
        taskNote: taskNote
      },
    })

    // returning the product returned by the API
    return response
  },

  /** Upload the CRS Credit Report */
  uploadCRSReport: async function (apiKey, clientID, filename) {

    const response = await api.request({
      url: `/audit/uploadCreditReport`,
      method: "POST",
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        apiKey: apiKey,
        clientID: clientID,
        filename: filename,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** Get updated Debts */
  getUpdatedDebts: async function (apiKey, clientID) {

    const response = await api.request({
      url: `/audit/getUpdatedDebts`,
      method: "POST",
      params: {
        apiKey: apiKey,
        clientID: clientID,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** Update the Mongo database with the audit items */
  updateAuditItems: async function (auditItems) {

    const response = await api.request({
      url: `/audit/updateAudit`,
      method: "POST",
      params: {
        results: auditItems,
      },
    })

    // returning the product returned by the API
    return response
  },

  //Update Task Complete on DPP
  updateTaskComplete: async function (apiKey, taskID) {

    const response = await api.request({
      url: `/audit/updateTaskComplete`,
      method: "POST",
      params: {
        apiKey: apiKey,
        taskID: taskID,
      },
    })

    // returning the product returned by the API
    return response
  },

  //Find and upload Credit Report to DPP based on Client ID
  pullCreditReport: async function (clientID, clientSSN) {

    const response = await api.request({
      url: `/azure/clientCreditPull`,
      method: "POST",
      data: {
        clientID: clientID,
        clientSSN: clientSSN,
      },
    })

    // returning the product returned by the API
    return response
  },

  /** For the new CRS Credit Report for the client */
  getNewClientCR: async function (firstName, lastName, street1, city, state, zip, ssn, dobMonth, dobDay, dobYear) {

    //'Anderson', 'Laurie', '9817 Loop Blvd', 'Apt G', 'California City', 'CA', '935051352', '666455730'

    const response = await api.request({
      url: `/audit/getNewClientCR`,
      method: "POST",
      params: {
        firstName: firstName,
        lastName: lastName,
        street1: street1,
        street2: "",
        city: city,
        state: state,
        zip: zip,
        ssn: ssn,
        dob: dobYear + "-" + dobMonth + "-" + dobDay
      },
    })

    // returning the product returned by the API
    return response
  },

}
