import * as React from 'react';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { clgAPI } from '../network/clgAPI';

import './login.css';

//Images
import Logo from '../assets/CLG_Logo_Retina.png';
import SideImage from '../assets/3275434.jpg';

//Page redirect
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {

    const [email, setEmail] = React.useState('');

    const navigate = useNavigate();

    //Access the API to change their password
    const handleEmailReset = () => {
        clgAPI.forgotPassword(email).then((response) => {

            //If successful, redirect to the login page
            if (response.status === 204) {
            
                Swal.fire({
                    icon: 'success',
                    title: 'Change Password Link Emailed!',
                    text: 'Your change password link has been emailed to you, please check your email'
                });

                navigate('/login');
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: error.response.data.message,
            });
        });
    };

    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: 'Email field is empty, please enter your email',
            });
            return;
        }
        else {
            handleEmailReset();
        }
    };

    //Render the Login page
    return (
        <div className="container">
            <div className="left-column">
                <div className='left-column-box'>
                    <img width="700" src={SideImage} />
                </div>
            </div>
            <div className="right-column">
                <div className='logoDiv'>
                        <img width="150" src={Logo} />
                    </div>
                <div className='right-column-box'>
                    <div className='titleDiv'>
                        <span>Forgot Password? </span>
                    </div>
                    <div className='subtitleDiv'>
                        <span>Please enter the email you registered with on this platform.</span>
                    </div>
                    <div className='inputBox'>
                        <TextField
                            id="Email"
                            label="Email"
                            color='success'
                            className='input'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='buttonDiv'>
                        <button className='buttonReset' onClick={handleSubmit}>RESET PASSWORD</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;