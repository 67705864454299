import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { clgAPI } from '../network/clgAPI';

//Components
import Header from './headerComponent';
import DataTable from './clientComponent';

//CSS
import './analyze.css';

//Progress Bar
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import snowflakeFile from '../assets/snowflake.json';

import debt_573397894 from '../assets/debts/573397894.json';
import debt_722845583 from '../assets/debts/722845583.json';
import debt_751452128 from '../assets/debts/751452128.json';

import crs_573397894 from '../assets/crs/573397894.json';
import crs_722845583 from '../assets/crs/722845583.json';
import crs_751452128 from '../assets/crs/751452128.json';

/** Progress Bar */
function LinearProgressWithLabel(props) {

    return (
        <div className='analyzeProgressBarContainer'>
            <div className='row'>
                <div className='col-12 analyzeProgressTitleDiv'>
                    <div className="analyzeProgressBarDiv">
                        <LinearProgress variant="determinate" {...props} className='analyzeProgressBarDiv'/>
                        <span className="analyzeProgressBarPercDiv">{Math.round(props.value)}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function Analyze() {

    //const [snowflakeData, setSnowflakeData] = useState([]);
    const [DebtPayProDataApiKey, setDebtPayProDataApiKey] = useState("");
    const [changedDebts, setChangedDebts] = useState([]);
    
    const [invalidSSN, setInvalidSSN] = useState([]);
    const [clientCRSPulled, setClientCRSPulled] = useState([]);

    //Client ID
    const [clientValue, setClientValue] = useState('');

    //Client SSN
    const [clientSSN, setClientSSN] = useState('');

    //Progress Bars
    const [buttonPressed, setButtonPress] = useState(false);
    const [clientPulling, setClientPulling] = useState(false);

    //Contants
    const Creditor_Debt_Status_Code = "7977";
    const Deleted_Debt_Status_Collection = "System - Debt Deleted Collection";
    const Deleted_Debt_Status_Creditor = "System - Debt Deleted Creditor";

    //Progress Bar
    const [progress, setProgress] = useState(10);

    /******************************* Analyze Section ****************************/
    //Get Snowflake data from API Async
    async function accessSnowflake () {

        try {
            const token = localStorage.getItem('CLGAuditToken');
            const response = await clgAPI.getSnowflakeData(token);
        
            if (response.status === 201) {
                //console.log(response.data);
                return response.data;
            }
        } catch (error) {
            //console.log(error.response.data.message);
            Swal.fire({ 
                icon: 'error',
                title: 'Snowflake Data Error',
                text: error.response,
            });
            throw error; // Rethrow the error if needed
        }
    }

    // Get the Debt Pay Pro Data from the API
    async function accessDebtPayPro(apiKey, clientID) {
        try {
            const response = await clgAPI.getDebtPayProData(apiKey, clientID);
        
            if (response.status === 201) {
                //console.log("Debt Pay Pro --");
                //console.log(response.data);
                return response.data;
            }
        } catch (error) {
            //console.log(error.response.data.message);
            Swal.fire({ 
                icon: 'error',
                title: 'Debt Pay Pro Access Error',
                text: error.response,
            });
            throw error; // Rethrow the error if needed
        }
    }
  

    // Get the CRS Data from the API
    async function accessCRS(clientID, firstName, lastName, address, address2, city, state, zip, ssn, dob) {

        //console.log("CRS Data: " + clientID + " " + firstName + " " + lastName + " " + address + " " + address2 + " " + city + " " + state + " " + zip + " " + ssn);

        try {
            const response = await clgAPI.getCRSData(
                clientID,
                firstName,
                lastName,
                address,
                address2,
                city,
                state,
                zip,
                ssn,
                dob
            );
            /*const response = await clgAPI.getCRSData(
                clientID,
                'Anderson',
                'Laurie',
                '9817 Loop Blvd',
                'Apt G',
                'California City',
                'CA',
                '935051352',
                '666455730'
            );*/
        
            if (response.status === 201) {
                //console.log("CRS Report");
                //console.log(response.data);
                return response.data;
            }
        } catch (error) {
            //console.log(error.response.data.message);
            Swal.fire({ 
                icon: 'error',
                title: 'CRS Access Error',
                text: error.response,
            });
            throw error; // Rethrow the error if needed
        }
    }

    //Upload the JSON file to the API
    async function uploadCRFile (dpp_key, clientID) {
        try {
            const response = await clgAPI.uploadCRSReport(
                dpp_key, 
                clientID, 
                'CreditReport.pdf'
            );
        
            if (response.status === 201) {
                //console.log("Uploaded CRS File");
                console.log(response.data);
            }
        } catch (error) {
            //console.log(error.response.data.message);
            throw error; // Rethrow the error if needed
        }
    }

    //Get the Credit Report from CRS and Upload it to DPP
    async function pullCreditReport (clientID, clientSSN) {
        try {
            const response = await clgAPI.pullCreditReport(clientID, clientSSN);
        
            if (response.status === 200) {
                //console.log(response.data);
                return response.data;
            }
        } catch (error) {
            //console.log(error.response.data.message);
            throw error; // Rethrow the error if needed
        }
    }

    //Function to iterate through the Snowflake data and find the corresponding Debt Pay Pro client
    async function getDPPApiKey () {

        try {
            const token = localStorage.getItem('CLGAuditToken');
            let response = await clgAPI.getDebtPayProDataApiKey(token);

            setDebtPayProDataApiKey(response.data.response.api_key);
            //console.log(response.data.response.api_key);

            return response.data.response.api_key;
        } catch (error) {
            Swal.fire({ 
                icon: 'error',
                title: 'Debt Pay Pro ApiKey Error',
                text: error.response,
            });
            throw error;
        }
    }

    //Function to mark Task as complete on Debt Pay Pro
    async function completeTaskRecord (dpp_apiKey, task_id) {

        const response = await clgAPI.updateTaskComplete(dpp_apiKey, task_id);

        //console.log("Marked Task Record: " +  task_id + " as Completed");
        //console.log(response);

        if (response !== undefined) 
            return await response.data;
        else
            return null;    
      
    }

    //Function to Update Debt Status
    async function updateDebtStatus (dpp_apiKey, debt_id, debt_amount, debt_amount_current, creditor, clientID, customSection, debtSection) {

        try {
            const response = await clgAPI.updateDebtRecord(dpp_apiKey, debt_id, debt_amount, debt_amount_current, creditor, clientID, customSection, debtSection);

            if (response.status === 201) {
                //console.log("Updated Debt Record");
                //console.log(response.data);
                return await response.data;
            }
        } catch (error) {
            //console.log(error.response.data.message);
            Swal.fire({
                icon: 'error',
                title: 'Debt Record Update Error',
                text: error.response,
            });
            throw error;
        }
    }

    //Function to Create Task Record
    async function createTaskRecord (dpp_apiKey, clientID, taskName, taskCode, taskNotes) {

        try {
            const response = await clgAPI.updateTaskRecord(dpp_apiKey, clientID, taskName, taskCode, taskNotes);

            if (response.status === 201) {
                //console.log("Created Task Record");
                //console.log(response.data);
                return await response.data;
            }
        } catch (error) {
            //console.log(error.response.data.message);
            Swal.fire({
                icon: 'error',
                title: 'Task Record Creation Error',
                text: error.response,
            });
            throw error;
        }
    }

    //Function to iterate through the Snowflake data and find the corresponding Debt Pay Pro client
    function findDebtPayProClient (snowflakeData) {

        let contactIDs = [];

        //Iterate through the Snowflake data
        for (let i = 0; i < snowflakeData.length; i++) {
            
            const contactID = snowflakeData[i].CONTACT_ID;

            //Add to array
            contactIDs.push(contactID);
        }

        return contactIDs;
    }
         
    /**
     * Function to analyze CRS data, find the matching account numbers from debtors and decide
     * figure out which debts are still in collections. 
     * 
     * @param {*} data                    JSON data from CRS
     * @param {*} ddp_accountNumbers      Account numbers from Debt Pay Pro
     ***/ 
    async function analyzeCRS (dppData, crsData) {

        //Compare CRS File tradeline array objects, accountNumber and DDP File array objects, og_account_num - When both accounts number match
        let result = dppData.map(({og_account_num}) => ({
            og_account_num, 
            accountNumber:crsData.find( k => k.accountNumber === og_account_num )?.['accountNumber'] 
        }));

        //Obtain the accounts where accountNumber is undefined (Have been removed from the latest CRS)
        let deleted_accounts = result
        .filter(({ accountNumber }) => accountNumber === undefined)
        .map(({ og_account_num }) => og_account_num);
    
        //Obtain the accounts where accountNumber is defined (Still in CRS)
        let unchanged_accounts = result
        .filter(({ accountNumber }) => accountNumber !== undefined)
        .map(({ og_account_num }) => og_account_num);
    
        /********* New Section, because DPP removes the last 4 digits of an Account ID, we need to match the pre-digits to the CRS Report *************/
        //Check CRS against DPP's debts with the last 4 numbers omitted, since DPP removes the last 4 digits
        const additional_results = dppData.map(({og_account_num}) => ({
                og_account_num, 
                accountNumber:crsData.find(k => k.accountNumber.slice(0, -4) === og_account_num)?.['accountNumber']
        }));

        // Filter additional_results to find matches in deleted_accounts
        const matches = additional_results.filter(({ og_account_num, accountNumber }) => accountNumber !== undefined && deleted_accounts.includes(og_account_num));

        // Remove matches from deleted_accounts
        deleted_accounts = deleted_accounts.filter(account => !matches.some(({ og_account_num }) => og_account_num === account));

        // Add matches to unchanged_accounts
        unchanged_accounts = [...unchanged_accounts, ...matches.map(({ og_account_num }) => og_account_num)];
        /*******************************************************/

        /********* New Section, because DPP removes the last 4 digits of an Account ID, we need to match the pre-digits to the CRS Report *************/
        //Check CRS against DPP's debts with the last 4 numbers omitted, since DPP removes the last 4 digits
        const additional_results2 = dppData.map(({og_account_num}) => ({
            og_account_num, 
            accountNumber:crsData.find(k => k.accountNumber === og_account_num.slice(0, -4))?.['accountNumber']
        }));

        // Filter additional_results to find matches in deleted_accounts
        const matches2 = additional_results2.filter(({ og_account_num, accountNumber }) => accountNumber !== undefined && deleted_accounts.includes(og_account_num));

        // Remove matches from deleted_accounts
        deleted_accounts = deleted_accounts.filter(account => !matches2.some(({ og_account_num }) => og_account_num === account));

        // Add matches to unchanged_accounts
        unchanged_accounts = [...unchanged_accounts, ...matches2.map(({ og_account_num }) => og_account_num)];
        /*******************************************************/

        return [deleted_accounts, unchanged_accounts];
    }

    /** Determine if the Debt has any of the 4 criteria to which we would pull the CRS data */
    function checkDebtStatus (debt) {

        for (const debtElement of debt) {

            const customs = debtElement["customs"];

            if ((customs !== null) && (customs !== undefined)) {

                //Iterate through the custom fields
                const creditor_debt_status = debtElement["customs"].find(item => item.id === Creditor_Debt_Status_Code); 

                if ((creditor_debt_status !== null) && (creditor_debt_status !== undefined)) {

                    //console.log("Creditor Debt Status: ");
                    //console.log(creditor_debt_status);

                    if ((creditor_debt_status["value"].trim() === "Docs - Deletion Doc Received Collection") || (creditor_debt_status["value"].trim() === "Docs - Deletion Doc Received Collection") || (creditor_debt_status["value"].trim() === "System - No Response to Initial Dispute") || (creditor_debt_status["value"].trim() === "System - No Response to Invalidation")) {
                        return true;
                    }
                }
            }
            
            //Check debt status
            const debt_status = debtElement["debt_status"];

            if ((debt_status !== null) && (debt_status !== undefined)) {

                //console.log("Debt Status: ");
                //console.log(debt_status);
                
                if ((debt_status["label"].trim() === "Docs - Deletion Doc Received Collection") || (debt_status["label"].trim() === "Docs - Deletion Doc Received Creditor") || (debt_status["label"].trim() === "System - No Response to Initial Dispute") || (debt_status["label"].trim() === "System - No Response to Invalidation")) {
                    //console.log("True");
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Function to deal with accounts that have been deleted, updating both the Debt Status and Task Records
     */
    async function updateRemovedDebt (dpp_apiKey, deleted_accounts, DebtPayPro_file, clientID) {
        
        const filteredObjects = DebtPayPro_file.filter(obj => deleted_accounts.includes(obj.og_account_num));
    
        let changed_debts = [];

        //Update Debt Status
        //filteredObjects.forEach((debt) => {
        for (const debt of filteredObjects) {
        
            let org_type = "";
            let debt_id = "";
            let org_name= "";
    
            //Is this a creditor of in collections? (null means creditor, value is in collections)
            if ((debt["debt_buyer"] === null) || (debt["debt_buyer"] === undefined) || (debt["debt_buyer"] === "0")) {
                org_type = "CREDITOR";
                org_name = debt["creditor"]["company_name"];
            }
            else {
                org_type = "COLLECTOR";
                org_name = debt["debt_buyer"]["company_name"];
            }
        
            //debt_id = debt["og_account_num"];
            debt_id = debt["id"];

            let debt_status;

            //Check to see if CREDITOR
            if (org_type === "CREDITOR") {

                //Go through Debt Status instead
                if ((debt["debt_status"] !== null) && (debt["debt_status"] !== undefined)) {
                    debt_status = debt["debt_status"]["label"];
                }

                //Go through Customs just incase
                else { 
                    //Iterate through the custom fields
                    const creditor_debt_status = debt["customs"].find(item => item.id === Creditor_Debt_Status_Code); 

                    //Custom field is null
                    if ((creditor_debt_status === null) || (creditor_debt_status === undefined)) {
                        debt_status = "No Debt Status"; 
                    }
                    else {
                        debt_status = creditor_debt_status.value;
                    }
                } 
            }

            //Check Creditor Debt Status for COLLECTIONS
            if (org_type === "COLLECTOR") {

                //Iterate through the custom fields
                const creditor_debt_status = debt["customs"].find(item => item.id === Creditor_Debt_Status_Code); 

                //Custom field is null
                if ((creditor_debt_status === null) || (creditor_debt_status === undefined)) {

                    //Go through Debt Status instead
                    if ((debt["debt_status"] !== null) && (debt["debt_status"] !== undefined)) {
                        debt_status = debt["debt_status"]["label"];
                    }
                    else { 
                        debt_status = "No Debt Status"; 
                    } 
                }
                else {
                    debt_status = creditor_debt_status.value;
                }
            }

            //Task Record
            let taskName = "";
            let taskCode = 0;
            let flag = false;
        
            //Check for these use cases 
            switch (debt_status.trim()) {
                case "Docs - Deletion Doc Received Collection":
                    taskName = "Debt Deleted Email";
                    taskCode = 43478;
                    flag = true;
                    break;
                case "Docs - Deletion Doc Received Creditor": 
                    taskName = "Debt Deleted Email";
                    taskCode = 43478;
                    flag = true;
                    break;
                case "System - No Response to Initial Dispute":
                    taskName = "Debt Deleted Email";
                    taskCode = 43478;
                    flag = true;
                    break;
                case "System - No Response to Invalidation": 
                    taskName = "Debt Deleted Email";
                    taskCode = 43478;
                    flag = true;
                    break;   
                default:
                    flag = false;
                    break;
            }

            if (flag) {

                let customSection;
                let debtSection;

                let name = "";

                if ((org_name === null) || (org_name === undefined)) {
                    name = 'N/A';
                } else {
                    name = org_name;
                }    

                //Update Customs field for Collector
                if (org_type === "COLLECTOR") {
                    
                    //debtSection = { object: "debt_status", status_id: "1008", label: "Docs - Deletion Doc Received Collection" }
                    debtSection = "1027";

                    //Make sure this section is not empty
                    if ((debt['customs'] !== null) && (debt['customs'] !== undefined)) {

                        //Update Custom Debt Section
                        customSection = debt['customs'].map((custom) => {
                        
                            //Check to see if custom has code 7977 (Debt Status)
                            if (custom['id'] === Creditor_Debt_Status_Code) {
                                custom['value'] =  Deleted_Debt_Status_Collection;
                                return custom;
                            }
                            else {
                                return custom;
                            }
                        });
                    }
                    //Empty
                    else {

                        customSection = [ { label: "Creditor Debt Status", id: "7977", value: Deleted_Debt_Status_Collection, type: "Multiple Choice"} ];
                    }

                    //Add to Array
                    changed_debts.push( { debt_id: debt_id, changes: 'Debt Removed', org_type: org_type, org_name: name, old_debt_status: debt_status, new_debt_status: Deleted_Debt_Status_Collection, task_notes: taskCode + ' - ' + taskName});
                }

                //Update only debt_status for Creditor
                else if (org_type === "CREDITOR") {

                    //debtSection = { object: "debt_status", status_id: "954", label: "Docs - Deletion Doc Received Creditor" }
                    debtSection = "1007";

                    //Make sure this section is not empty
                    if ((debt['customs'] !== null) && (debt['customs'] !== undefined)) {

                        //Update Custom Debt Section
                        customSection = debt['customs'].map((custom) => {
                        
                            //Check to see if custom has code 7977 (Debt Status)
                            if (custom['id'] === Creditor_Debt_Status_Code) {
                                custom['value'] = Deleted_Debt_Status_Creditor;
                                return custom;
                            }
                            else {
                                return custom;
                            }
                        });
                    }
                    //Empty
                    else {
                        customSection = [ { label: "Creditor Debt Status", id: "7977", value: Deleted_Debt_Status_Creditor, type: "Multiple Choice"} ];
                    }

                    //Add to Array
                    changed_debts.push( { debt_id: debt_id, changes: 'Debt Removed', org_type: org_type, org_name: name, old_debt_status: debt_status, new_debt_status: Deleted_Debt_Status_Creditor, task_notes: taskCode + ' - ' + taskName});
                }
                
                //Update Debt Record
                const debtupdateresponse = await updateDebtStatus(dpp_apiKey, debt_id, debt['original_debt_amount'], debt['current_debt_amount'], debt['creditor'], clientID, customSection, debtSection);

                 // Pause for a specific time (e.g., 3 seconds)
                 setTimeout(function () {
                    console.log('Waiting');
                }, 3000);

                //Update Task Record
                const taskresponse = await createTaskRecord(dpp_apiKey, clientID, taskName, taskCode, org_type + " - " + debt_id + ": "  + name);

                 // Pause for a specific time (e.g., 3 seconds)
                 setTimeout(function () {
                    console.log('Waiting');
                }, 3000);
            }
        };

        //Do a DDP Debt pull if we updated any debts
        if (changed_debts.length > 0) {
            clgAPI.getUpdatedDebts(dpp_apiKey, clientID).then((response) => {
                if (response.status === 201) {
                    console.log("Downloaded updated Debt Files");
                    //console.log(response.data);
                }
            });
        }

        return changed_debts;
    }

    /**
     * Function to deal with accounts that have not been deleted, updating both the Debt Status and Task Records
     */
    async function updateUnchangedDebt (dpp_apiKey, unchanged_accounts, DebtPayPro_file, clientID) {

        const filteredObjects = DebtPayPro_file.filter(obj => unchanged_accounts.includes(obj.og_account_num));
    
        let unchanged_debts = [];
    
        //Update Debt Status
        for (const debt of filteredObjects) {

            let org_type = "";
            let debt_id = "";
            let org_name= "";
    
            //Is this a creditor of in collections? (null means creditor, value is in collections)
            if ((debt["debt_buyer"] === null) || (debt["debt_buyer"] === undefined) || (debt["debt_buyer"] === "0")) {
                org_type = "CREDITOR";
                org_name = debt["creditor"]["company_name"];
            }
            else {
                org_type = "COLLECTOR";
                org_name = debt["debt_buyer"]["company_name"];
            }
        
            //debt_id = debt["og_account_num"];
            debt_id = debt["id"];

            //Iterate through the custom fields
            let debt_status;
            
            //Check to see if CREDITOR
            if (org_type === "CREDITOR") {

                //Go through Debt Status instead
                if ((debt["debt_status"] !== null) && (debt["debt_status"] !== undefined)) {
                    debt_status = debt["debt_status"]["label"];
                }

                //Go through Customs just incase
                else { 
                    //Iterate through the custom fields
                    const creditor_debt_status = debt["customs"].find(item => item.id === Creditor_Debt_Status_Code); 

                    //Custom field is null
                    if ((creditor_debt_status === null) || (creditor_debt_status === undefined)) {
                        debt_status = "No Debt Status"; 
                    }
                    else {
                        debt_status = creditor_debt_status.value;
                    }
                } 
            }

            //Check Creditor Debt Status for COLLECTIONS
            if (org_type === "COLLECTOR") {

                //Iterate through the custom fields
                const creditor_debt_status = debt["customs"].find(item => item.id === Creditor_Debt_Status_Code); 

                //Custom field is null
                if ((creditor_debt_status === null) || (creditor_debt_status === undefined)) {

                    //Go through Debt Status instead
                    if ((debt["debt_status"] !== null) && (debt["debt_status"] !== undefined)) {
                        debt_status = debt["debt_status"]["label"];
                    }
                    else { 
                        debt_status = "No Debt Status"; 
                    } 
                }
                else {
                    debt_status = creditor_debt_status.value;
                }
            }
            
            //Task Record
            let taskName = "";
            let taskCode = 0;
            let flag = false;

            /************ Revert Error Section
            console.log("Debt ID: " + debt_id + " Debt Status: " + debt_status);

            let customSection;
                
            //Revert Error, remove after run
            if (debt_status === Deleted_Debt_Status_Creditor) {
            
                debt_status = "Docs - Deletion Doc Received Creditor";

                //Make sure this section is not empty
                if ((debt['customs'] !== null) && (debt['customs'] !== undefined)) {

                    //Update Custom Debt Section
                    customSection = debt['customs'].map((custom) => {
                    
                        //Check to see if custom has code 7977 (Debt Status)
                        if (custom['id'] === Creditor_Debt_Status_Code) {
                            custom['value'] = "Docs - Deletion Doc Received Creditor";
                            return custom;
                        }
                        else {
                            return custom;
                        }
                    });
                }
                //Empty
                else {
                    customSection = [ { label: "Creditor Debt Status", id: "7977", value: "Docs - Deletion Doc Received Creditor", type: "Multiple Choice"} ];
                }

                //Update Debt Record
                const debtupdateresponse = await updateDebtStatus(dpp_apiKey, debt_id, debt['original_debt_amount'], debt['current_debt_amount'], debt['creditor'], clientID, customSection, "954");
            }
            else if (debt_status === Deleted_Debt_Status_Collection) {
                debt_status = "Docs - Deletion Doc Received Collection";

                //Make sure this section is not empty
                if ((debt['customs'] !== null) && (debt['customs'] !== undefined)) {

                    //Update Custom Debt Section
                    customSection = debt['customs'].map((custom) => {
                    
                        //Check to see if custom has code 7977 (Debt Status)
                        if (custom['id'] === Creditor_Debt_Status_Code) {
                            custom['value'] = "Docs - Deletion Doc Received Collection";
                            return custom;
                        }
                        else {
                            return custom;
                        }
                    });
                }
                //Empty
                else {
                    customSection = [ { label: "Creditor Debt Status", id: "7977", value: "Docs - Deletion Doc Received Collection", type: "Multiple Choice"} ];
                }

                //Update Debt Record
                const debtupdateresponse = await updateDebtStatus(dpp_apiKey, debt_id, debt['original_debt_amount'], debt['current_debt_amount'], debt['creditor'], clientID, customSection, "1008");
            }**************************************/

            //Check for these use cases 
            switch (debt_status.trim()) {
                case "Docs - Deletion Doc Received Collection":
                    taskName = "Generate Deletion Follow-Up Letter";
                    taskCode = 43470;
                    flag = true;
                    break;
                case "Docs - Deletion Doc Received Creditor":
                    taskName = "Generate Deletion Follow-Up Letter";
                    taskCode = 43470;
                    flag = true;
                    break;
                case "System - No Response to Initial Dispute":
                    taskName = "Generate Initial Dispute Follow-Up Letter";
                    taskCode = 43479;
                    flag = true;
                    break;
                case "System - No Response to Invalidation":
                    taskName = "Generate Post Validation No Response Letter";
                    taskCode = 43409;
                    flag = true;
                    break;
                default:
                    flag = false;
                    break;
            }

            //console.log("Task Name: " + taskName + " Task Code: " + taskCode);

            if (flag) {
        
                let name = "";

                if ((org_name === null) || (org_name === undefined)) {
                    name = 'N/A';
                } else {
                    name = org_name;
                }

                //Update Task Record
                const taskresponse = await createTaskRecord(dpp_apiKey, clientID, taskName, taskCode, org_type + " - " + debt_id + ": "  + name);
                
                //Add to Array
                unchanged_debts.push( { debt_id: debt_id, changes: 'Debt Unchanged', org_type: org_type, org_name: name, old_debt_status: debt_status, new_debt_status: debt_status, task_notes: taskCode + ' - ' + taskName});
            
                // Pause for a specific time (e.g., 5 seconds)
                setTimeout(function () {
                    console.log('Waiting');
                }, 3000);
            }
        };
    
        //console.log("Unchanged Debts to Display");
        //console.log(unchanged_debts);

        return unchanged_debts;
    }

    /**
     * Function to fetch the data from the APIs
     */
    async function analyzeData() {

        //Array to hold Debt Changes
        let debt_changes = [];

        //Array to hold ClientIDs with invalid SSNs
        let invalid_ssn = [];

        //Array to hold ClientIDs with SSNs that were pulled from CRS
        let client_crs_pulled = [];

        setButtonPress(true);

        //Set Progress
        setProgress(0);

        try {

            const snowflakeData = await accessSnowflake();
            //const snowflakeData = snowflakeFile;  //--Used for single test of a json file

            // Pause for a specific time (e.g., 5 seconds)
            await new Promise((resolve) => setTimeout(resolve, 5000));    

            // No data found on Snowflake
            if (snowflakeData.length <= 0) {

                Swal.fire({
                    icon: 'error',
                    title: 'Empty datatable on Snowflake',
                    text: 'There is no data found on Snowflake. Please check the Snowflake database.',
                });

                //Remove Progress Bar
                setButtonPress(false);

                //Set Progress
                setProgress(100); 

                return;
            }

            // Call API 2 after API 1 is done
            const dpp_apiKey = await getDPPApiKey();

            // Get the contact IDs from the Snowflake data
            const contactIDs = findDebtPayProClient(snowflakeData);

            console.log("DPI Key: " + dpp_apiKey);

            console.log("Client IDs");
            console.log(contactIDs);

            // Iterate through the contact IDs and get the Debt Pay Pro data
            for (let i = 0; i < contactIDs.length; i++) {
                console.log("Client ID: " + contactIDs[i]);

                //Get the Debt Pay Pro Debt Information
                let DPPdata = await accessDebtPayPro(dpp_apiKey, contactIDs[i]);
             
                // Pause for a specific time (e.g., 10 seconds)
                await new Promise((resolve) => setTimeout(resolve, 5000));

                //Set Progress
                setProgress(i / contactIDs.length * 100);
                
                //Check to see if we need to continue - must meet one of the 4 criteria
                let status;
                
                status = checkDebtStatus(DPPdata);
                console.log(contactIDs[i] + " - " + status);

                /** Only if true (Contains one of the 4 statuses) **/
                if (status) {

                    //Remove any hyphens from the SSN 
                    const ssn = snowflakeData[i].SSN.replace(/-/g, '');

                    //Get the CRS Data
                    const crsData = await accessCRS(contactIDs[i], snowflakeData[i].FIRSTNAME, snowflakeData[i].LASTNAME, snowflakeData[i].ADDRESS, snowflakeData[i].ADDRESS2, snowflakeData[i].CITY, snowflakeData[i].STATE, snowflakeData[i].ZIP, ssn, snowflakeData[i].DOB);

                    //Skip if we have a CRS pull error
                    if (crsData.length < 1) {
                        invalid_ssn.push(contactIDs[i]);
                        console.log("SSN invalid for: " + contactIDs[i]);

                        // Pause for a specific time (e.g., 5 seconds)
                        await new Promise((resolve) => setTimeout(resolve, 5000));
                        continue;
                    }

                    //console.log("CRS Data pulled for : " + contactIDs[i]);
                    
                    //Add Client ID to CRS Pulled Array
                    client_crs_pulled.push(contactIDs[i]);

                    // Pause for a specific time (e.g., 3 seconds)
                    await new Promise((resolve) => setTimeout(resolve, 4000));

                    //Mark the Task complete for Credit Pull on DPP
                    const taskresponse = await completeTaskRecord(dpp_apiKey, snowflakeData[i].TASK_ID);
                                        
                    // Pause for a specific time (e.g., 3 seconds)
                    await new Promise((resolve) => setTimeout(resolve, 3000));

                    const [deleted_accounts, unchanged_accounts] = await analyzeCRS(DPPdata, crsData);

                    console.log("Deleted Accounts");
                    console.log(deleted_accounts);

                    console.log("Unchanged Accounts");
                    console.log(unchanged_accounts);

                    //Upload the new CRS Credit Report
                    const response = await uploadCRFile(dpp_apiKey, contactIDs[i]);

                    const removed_debts = await updateRemovedDebt(dpp_apiKey, deleted_accounts, DPPdata, contactIDs[i]);

                    // Pause for a specific time (e.g., 3 seconds)
                    await new Promise((resolve) => setTimeout(resolve, 3000));

                    const unchanged_debts = await updateUnchangedDebt(dpp_apiKey, unchanged_accounts, DPPdata, contactIDs[i]);

                    //Merge the two arrays
                    const debt_changes_array = removed_debts.concat(unchanged_debts);

                    //Save them for display
                    if (debt_changes_array.length > 0)
                        debt_changes.push({ clientID: contactIDs[i], debt: debt_changes_array });

                    // Pause for a specific time (e.g., 3 seconds)
                    await new Promise((resolve) => setTimeout(resolve, 3000));             
                }
            }
        } catch (error) {
          console.error('An error occurred:', error);
        }

        //console.log("Changed Debts");
        //console.log(debt_changes);

        //Remove Progress Bar
        setButtonPress(false);

        //Set Progress
        setProgress(100);  

        //Set the Changes
        setChangedDebts(debt_changes);

        //Set the Invalid SSNs
        setInvalidSSN(invalid_ssn);

        //Set the Client CRS Pulled
        setClientCRSPulled(client_crs_pulled);

        /* Update Database */
        if (debt_changes.length > 0) {
            clgAPI.updateAuditItems(debt_changes).then((response) => {
                if (response.status === 201) {
                    console.log("Updated Database");
                    //console.log(response.data);
                }
            });
        }
    }

    const Overlay = () => {
        return (
          <div className="overlay">
            <div className="progress-container">
                <LinearProgressWithLabel value={progress} /> 
            </div>
          </div>
        );
    };
    /********************************************************************************/

    /******************************* Credit Pull Section ****************************/
    /**
     * Function to set the Client ID
     * @param {*} event 
     */
    const handleIDInputChange = (event) => {
        setClientValue(event.target.value);
    };

    const handleSSNInputChange = (event) => {
        setClientSSN(event.target.value);
    }

    /**
     * Function to fetch the data from the APIs
     */
    async function submitCreditPull() {

        if (clientValue.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Client ID is empty',
                text: 'Please enter a Client ID.',
            });
        }

        else {
            // Call API 2 after API 1 is done
            setClientPulling(true);

            //Pause for 2 secs
            await new Promise((resolve) => setTimeout(resolve, 2000));

            try {
                //Upload the new CRS Credit Report
                const response = await pullCreditReport(clientValue, clientSSN);

                if ((response !== undefined) || (response !== 'Error Obtaining Credit Report')) {

                    setClientPulling(false);

                    Swal.fire({
                        icon: 'success',
                        title: 'New Credit Report Pulled',
                        text: "ClientID: " + clientValue,
                    });

                    // Assuming the response contains a URL to the file
                    const fileUrl = response.fileUrl;

                    //Download File to Computer
                    fetch(fileUrl, {
                        method: 'GET'
                    }).then(resp => resp.blob())
                        .then(blob => {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = clientValue + ".pdf"; // the filename you want
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                    })

                    setClientValue("");
                    setClientSSN("");
                }
            } catch (error) {
      
                console.log(error);

                setClientPulling(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Credit Report Error',
                    text: error.response,
                });

            }
        }
    }

    const CROverlay = () => {
        return (
            <div className="creditoverlay">
                <CircularProgress size={150}/>
            </div>
        );
    };
    /********************************************************************************/


    return (
        <div>
            <Header />
            <div className='analyzeTitleDiv'>
                <span className='analyzeSubDescription'>Credit Pull by Client ID and SSN</span>
                <div className='inputPanel'>
                    <input type="text" className="input" placeholder="Client ID" value={clientValue} onChange={handleIDInputChange} />
                    <input type="text" className="input" placeholder="Social Insurance Number" value={clientSSN} onChange={handleSSNInputChange} />
                </div>    
            </div>
            <div className='analyzeButtonDiv'>
                <button className="creditPullButton" onClick={submitCreditPull}>Pull Credit Report</button>
            </div>
            <hr className='hr-style'/>
            <div className='analyzeTitleDiv'>
                <span className='analyzeSubDescription'>This automation tool will help you to quickly navigate through your Snowflake client database to validate latest debt statuses between a client's Credit Report and their previously viewed debt records on Debt Pay Pro.</span>
                <span className='analyzeSubDescription'>Simply press the 'Analyze Clients' button to start.</span>
            </div>
            <div className='analyzeButtonDiv'>
                <button className="analyzeButton" onClick={analyzeData}>Analyze Clients</button>
            </div>
            {buttonPressed ? <Overlay /> : null}  {/* Progress for Analyze Clients */}
            {clientPulling ? <CROverlay /> : null} {/* Progress Bar for Credit Report Pull */}
            { (changedDebts.length > 0 || invalidSSN.length > 0) ? <DataTable data={changedDebts} invalids={invalidSSN} SSNs={clientCRSPulled}/> : null}
        </div>
    );
}


export default Analyze;