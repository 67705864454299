import React from 'react';

//Images
import Logo from '../assets/CLG_Logo_Retina.png';

//CSS
import './header.css';

const Header = () => {
  return (
        <header>
            <div className="header">
                <div className='headerlogoDiv'>
                    <img src={Logo} className="img-logo" alt="CLG Logo" />
                </div>
                <button className="headerButtonAudit" onClick={() => window.location.href = '/'}>Audit</button>
                <button className="headerButtonCR" onClick={() => window.location.href = '/clientCRPull'}>New Client Credit Report</button>
                <div >
                    <span className='headerTitleDiv'>Internal Debt Status Tool</span> 
                </div>
            </div>
            <hr className='hr-style'/>
        </header>
  );
};
 export default Header;





