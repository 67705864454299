import * as React from 'react';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { clgAPI } from '../network/clgAPI';

import './login.css';

//Images
import Logo from '../assets/CLG_Logo_Retina.png';
import SideImage from '../assets/boy-with-rocket-light.png';

//Compoenents
import Analyze from './analyzeComponent';

function Login() {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    //Persistent Login
    useEffect(() => {
        const token = localStorage.getItem('CLGAuditToken');
        if (token) {
            clgAPI.checklogintoken(token).then((response) => {
                if (response.status === 200) {
                    setLoggedIn(true);
                }
            }).catch((error) => {
                console.log(error.response.data.message);
            });
        }
    }, []);

    //Access the API to login the user
    const handleLogin = () => {
        clgAPI.login(email, password).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                console.log(response.data);

                //Store the token in the local storage
                localStorage.setItem('CLGAuditToken', response.data.tokens.refresh.token);

                //Set the loggedIn state to true
                setLoggedIn(true);
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            console.log(error.response.data.message);
        });
    };

    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: 'Email field is empty, please enter your email',
            });
            return;
        }
        else if (password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Password field is empty, please enter your password',
            });
            return;
        }
        else {
            handleLogin();
        }
    };

    if (!loggedIn) {
        //Render the Login page
        return (
            <div className="container">
                <div className="left-column">
                    <div className='left-column-box'>
                        <img width="700" src={SideImage} />
                    </div>
                </div>
                <div className="right-column">
                    <div className='logoDiv'>
                            <img width="150" src={Logo} />
                    </div>
                    <div className='right-column-box'>
                        <div className='titleDiv'>
                            <span>Welcome to CLG! 👋🏻</span>
                        </div>
                        <div className='subtitleDiv'>
                            <span>Please sign-in to your account to start the automation</span>
                        </div>
                        <div className='inputBox'>
                            <TextField
                                id="email"
                                label="Email"
                                color='secondary'
                                className='input'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='inputBox'>
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                color='secondary'
                                className='input'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className='forgotPasswordBox'>
                            <span><a href="/forgotPassword">Forgot Password?</a></span>
                        </div>
                        <div className='buttonDiv'>
                            <button className='button' onClick={handleSubmit}>SIGN IN</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        //Render the Dashboard page
        return (
            <Analyze />
        );
    }
}

export default Login;