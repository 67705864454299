import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { clgAPI } from '../../network/clgAPI';

//Components
import Header from '../headerComponent';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';

import '../../css/creditreportstyling.css';

function CreditReport() {

    const [firstName, setFirstName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [lastName, setLastName] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [socialInsuranceNumber, setSocialInsuranceNumber] = useState('');
    const [birthMonth, setBirthMonth] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [birthYear, setBirthYear] = useState('');

    const [clientCRSPulled, setClientCRSPulled] = useState([]);

    const [filename, setFilename] = useState(null);

    //Progress Bars
    const [clientPulling, setClientPulling] = useState(false);

    //Contants
    const Creditor_Debt_Status_Code = "7977";
    const Deleted_Debt_Status_Collection = "System - Debt Deleted Collection";
    const Deleted_Debt_Status_Creditor = "System - Debt Deleted Creditor";

    //Progress Bar
    const [progress, setProgress] = useState(10);

    //Login Check
    const [loggedIn, setLoggedIn] = useState(false);

    // Inside your component
    const navigate = useNavigate();

    
    //Persistent Login
    useEffect(() => {
        const token = localStorage.getItem('CLGAuditToken');
        if (token) {
            clgAPI.checklogintoken(token).then((response) => {
                if (response.status === 200) {
                    setLoggedIn(true);
                }
            }).catch((error) => {
                console.log(error.response.data.message);
            });
        }
        else {
            navigate('/');
        }
    }, []);

    /******************************* Credit Pull Section ****************************/
    /**
     * Function to set the Client ID
     * @param {*} event 
     */
    const handleInputChange = (event, setStateFunc) => {
        setStateFunc(event.target.value);
    };

    /** 
     * Function to display the Credit Report
     */
    function displayCreditReport() {

        console.log(filename);

        //Display two buttons

        //One to download the Credit Report
        return (
            <div>
                <hr/>
                <div className='titleDiv'>
                    <span className='CRTitle'>Credit Report</span>
                    <div className='mainDiv'>
                        <button className="openFileButton" onClick={() => openCreditReport(filename)}>Open Credit Report</button>
                        <button className="downloadFileButton" onClick={() => downloadCreditReport(filename)}>Download Credit Report</button>
                    </div>  
                </div>
            </div>
        );
    }

    /**
     * Download the Credit Report
     * @param {*} url 
     */
    const downloadCreditReport = (url) => {

        const filename = url.split('/').pop();

        fetch(url, {
            method: 'GET'
        }).then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename // the filename you want
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
        })
    };

    const openCreditReport = (url) => {
        console.log(url);
        window.open(url, '_blank');
    };

    /**
     * Function to fetch the data from the APIs
     */
    async function submitInfo() {

        if (firstName.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'First Name is empty',
                text: 'Please enter your first name',
            });
        }
        else if (lastName.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Last Name is empty',
                text: 'Please enter your last name',
            });
        }
        else if (streetAddress.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Street Address is empty',
                text: 'Please enter your residential address',
            });
        }
        else if (city.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'City is empty',
                text: 'Please enter your city',
            });
        }
        else if (state.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'State is empty',
                text: 'Please enter your state',
            });
        }
        else if (zipCode.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Zip Code is empty',
                text: 'Please enter your zip code',
            });
        }
        else if (socialInsuranceNumber.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Social Insurance Number is empty',
                text: 'Please enter your social insurance number',
            });
        }
        else if (birthMonth.length < 1) {
                
                Swal.fire({
                    icon: 'error',
                    title: 'Birth Month is empty',
                    text: 'Please enter your birth month',
                });
        }
        else if (birthDay.length < 1) {
                
                Swal.fire({
                    icon: 'error',
                    title: 'Birth Day is empty',
                    text: 'Please enter your birth day',
                });
        }
        else if (birthYear.length < 1) {
                
                Swal.fire({
                    icon: 'error',
                    title: 'Birth Year is empty',
                    text: 'Please enter your birth year',
                });
        }
        else {
            setClientPulling(true);

            //Pause for 2 secs
            await new Promise((resolve) => setTimeout(resolve, 2000));

            try {
                //Upload the new CRS Credit Report
                const response = await clgAPI.getNewClientCR(firstName, lastName, streetAddress, city, state, zipCode, socialInsuranceNumber, birthMonth, birthDay, birthYear);

                console.log(response);

                if (response.status === 201) {

                    console.log(response.data);

                    setClientPulling(false);

                    Swal.fire({
                        icon: 'success',
                        title: 'Credit Report successfully pulled',
                    });

                    setFilename(response.data);

                }
            } catch (error) {
      
                setClientPulling(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Credit Report Error',
                    text: error.response.data,
                });

            }
        }
    }

    const CROverlay = () => {
        return (
            <div className="creditoverlay">
                <CircularProgress size={150}/>
            </div>
        );
    };
    /********************************************************************************/

    return (
        <div>
            <Header />
            <div className='analyzeTitleDiv'>
                <span className='analyzeSubDescription'>Enter the Client's information to pull their Credit Report from CRS. After it is complete, you can view or download their report below</span>
            </div>
            <div className='mainDiv'>
                <div className='titleDiv'>
                    <span className='subDescription'>First Name</span>
                    <input type="text" className="newClientInput" value={firstName} onChange={(e) => handleInputChange(e, setFirstName)}  placeholder="First Name"/>
                    <span className='subDescription'>Birthdate</span>
                    <div className='dbDiv'>
                        <input type="text" className="newClientBirthdateInput" value={birthYear}  onChange={(e) => handleInputChange(e, setBirthYear)} placeholder="Birth Year"/>
                        <span className='dash'>-</span>
                        <input type="text" className="newClientBirthdateInput" value={birthMonth} onChange={(e) => handleInputChange(e, setBirthMonth)} placeholder="Birth Month"/>
                        <span className='dash'>-</span>
                        <input type="text" className="newClientBirthdateInput" value={birthDay} onChange={(e) => handleInputChange(e, setBirthDay)} placeholder="Birth Day"/>
                    </div>
                    <span className='subDescription'>Street Address</span>
                    <input type="text" className="newClientInput" value={streetAddress} onChange={(e) => handleInputChange(e, setStreetAddress)} placeholder="Street Address"/>
                    <span className='subDescription'>City</span>
                    <input type="text" className="newClientInput" value={city} onChange={(e) => handleInputChange(e, setCity)}  placeholder="City"/>
                </div>
                <div className='titleDiv'>
                    <span className='subDescription'>Last Name</span>
                    <input type="text" className="newClientInput" value={lastName} onChange={(e) => handleInputChange(e, setLastName)} placeholder="Last Name"/>
                    <span className='subDescription'>State</span>
                    <input type="text" className="newClientInput" value={state} onChange={(e) => handleInputChange(e, setState)} placeholder="State"/>
                    <span className='subDescription'>Zip Code</span>
                    <input type="text" className="newClientInput" value={zipCode} onChange={(e) => handleInputChange(e, setZipCode)} placeholder="Zip Code"/>
                    <span className='subDescription'>Social Insurance Number</span>
                    <input type="text" className="newClientSSNInput" value={socialInsuranceNumber} onChange={(e) => handleInputChange(e, setSocialInsuranceNumber)} placeholder="SSN"/>
                </div>
            </div>
                
            <div className='analyzeButtonDiv'>
                <button className="creditPullButton" onClick={submitInfo}>Acquire Credit Report</button>
            </div>
            {clientPulling ? <CROverlay /> : null} {/* Progress Bar for Credit Report Pull */}
            {filename !== null ? displayCreditReport() : ""} {/* Progress Bar for Credit Report Pull */}
        </div>
    );
}

export default CreditReport;