import * as React from 'react';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { clgAPI } from '../network/clgAPI';

import './login.css';

//Images
import Logo from '../assets/CLG_Logo_Retina.png';
import SideImage from '../assets/3293465.jpg';

//Page redirect
import { useNavigate } from 'react-router-dom';

function ResetPassword(props) {

    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setConfirmPassword] = React.useState('');

    const navigate = useNavigate();

    //Access the API to change their password
    const handleNewPassword = () => {
        clgAPI.resetPassword(props.token, password).then((response) => {

            //If successful, redirect to the login page
            if (response.status === 204) {
            
                Swal.fire({
                    icon: 'success',
                    title: 'Password Changed!',
                    text: 'Password successfully changed, please login with your new password',
                });

                navigate('/login');
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Token error',
                text: error.response.data.message,
            });
        });
    };

    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'New Password field is empty, please enter a new password',
            });
            return;
        }
        else if (passwordConfirm === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Confirm Password field is empty, please confirm your password',
            });
            return;
        }
        else if (password !== passwordConfirm) {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Passwords do not match, please try again',
            });
        }
        else {
            handleNewPassword();
        }
    };

    console.log(props.token);

    //Render the Login page
    return (
        <div className="container">
            <div className="left-column">
                <div className='left-column-box'>
                    <img width="700" src={SideImage} />
                </div>
            </div>
            <div className="right-column">
                <div className='logoDiv'>
                        <img width="150" src={Logo} />
                    </div>
                <div className='right-column-box'>
                    <div className='titleDiv'>
                        <span>Reset your password! </span>
                    </div>
                    <div className='subtitleDiv'>
                        <span>Please create a new password for your account</span>
                    </div>
                    <div className='inputBox'>
                        <TextField
                            id="password"
                            label="New Password"
                            type="password"
                            color='warning'
                            className='input'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='inputBox'>
                        <TextField
                            id="password1"
                            label="Confirm Password"
                            type="password"
                            color='warning'
                            className='input'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <div className='buttonDiv'>
                        <button className='buttonPasswordChange' onClick={handleSubmit}>CHANGE PASSWORD</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;