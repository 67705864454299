import React, { Fragment } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@mui/material';

import './client.css';

export default function SpanningTable(props) {

    const sample = props.data;
    const invalids = props.invalids;
    const SSNs = props.SSNs;

    console.log(invalids);

    /** Get today's date */
    const today = new Date();
    const month = today.toLocaleString('default', { month: 'short' });
    const date = month + ' ' + today.getDate() + ', ' +today.getFullYear();

    return (
        <>
        <TableContainer className="clientTableDiv">
            <h2 className="clientTitle">Client Debt Changes for {date}</h2>
            <Table className="clientTableStyle">
                <TableHead>
                    <TableRow>
                        <TableCell className="clientSubDescription">ClientID</TableCell>
                        <TableCell className="clientSubDescription">DebtID</TableCell>
                        <TableCell className="clientSubDescription">Changes</TableCell>
                        <TableCell className="clientSubDescription">Type</TableCell>
                        <TableCell className="clientSubDescription">Organization</TableCell>
                        <TableCell className="clientSubDescription">Old Debt Status</TableCell>
                        <TableCell className="clientSubDescription">New Debt Status</TableCell>
                        <TableCell className="clientSubDescription">Tasks Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {sample.map(item => (
                    <Fragment key={item.clientID}>
                        <TableRow>
                            <TableCell rowSpan={item.debt.length + 1}>
                                {item.clientID}
                            </TableCell>
                        </TableRow>
                        {item.debt.map(detail => (
                            <TableRow key={detail.debt_id}>
                                <TableCell>{detail.debt_id}</TableCell>
                                <TableCell>{detail.changes}</TableCell>
                                <TableCell>{detail.org_type}</TableCell>
                                <TableCell>{detail.org_name}</TableCell>
                                <TableCell>{detail.old_debt_status}</TableCell>
                                <TableCell>{detail.new_debt_status}</TableCell>
                                <TableCell>{detail.task_notes}</TableCell>
                            </TableRow>
                        ))}
                    </Fragment>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        {/* Show Client IDs where SSNs were pulled */}
        {SSNs.length > 0 ?
            <TableContainer className="clientTableDiv">
                <h2 className="clientTitle">Client Credit Reports Pulled</h2>
                <Table className="clientNoSSNTableStyle">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className="clientSubDescription">ClientID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {SSNs.map(item => (
                        <Fragment key={item}>
                            <TableRow>
                                <TableCell align="center" >
                                    {item}
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer> :
            ""
        }
        {/* Show Invalid SSNs if we have any */}
        {invalids.length > 0 ?
            <TableContainer className="clientTableDiv">
                <h2 className="clientTitle">Client Credit Report Pull Failed</h2>
                <Table className="clientNoSSNTableStyle">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className="clientSubDescription">ClientID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {invalids.map(item => (
                        <Fragment key={item}>
                            <TableRow>
                                <TableCell align="center" >
                                    {item}
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer> :
            ""
        }
        </>
    );
}