import './App.css';

//Login and Registration pages
import Login from './components/loginComponent';

//Forgot Password
import ForgotPassword from './components/forgotPasswordComponent';

//Forgot Password
import ResetPassword from './components/resetPasswordComponent';

//New Client Input
import Clientinput from './components/CreditReportComponent/creditreportComponent';

//Routing for Reactjst
import { Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const ResetPasswordRoute = () => {
  const { token } = useParams(); // Get the token parameter from the URL

  // You can pass the token to your ResetPassword component or perform any other logic here
  return <ResetPassword token={token} />;
};

const App = () => {
    return (
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/clientCRPull" element={<Clientinput />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPasswordRoute />} />
        </Routes>
      </>
    );
}

export default App;