import axios from "axios"

export const api = axios.create({
  withCredentials: false,
  //baseURL: 'http://localhost:3001/v1/',
  baseURL: 'https://audit.debtfixer.co:3000/v1/',
})


// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.log(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})